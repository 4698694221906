import log from 'loglevel';
import Swal from 'sweetalert2';
const baseAPIPath = process.env.REACT_APP_ENV && process.env.REACT_APP_ENV == "prod" ? "https://beta.byvr.ai" : "";

/* eslint-disable no-unused-vars */
const OIDCConfig = {
    CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID || '1092328488353-utdvta8h1asb4f971q4to2djek1bb32p.apps.googleusercontent.com',
    FACEBOOK_APP_ID: process.env.REACT_APP_FACEBOOK_APP_ID || '2072857143131253',
    REDIRECT_URI: process.env.REACT_APP_REDIRECT_URI || 'http://localhost:3000/google',
    SCOPES: 'openid profile email', // Adjust scopes as needed
};

// Development environment specific functions
const authenticateWithGoogleDev = () => {
    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${OIDCConfig.CLIENT_ID}&redirect_uri=${OIDCConfig.REDIRECT_URI}&response_type=code&scope=${OIDCConfig.SCOPES}`;
    window.location.href = authUrl;
};

const exchangeCodeForTokenDev = async (_code) => {
    window.history.pushState({}, null, OIDCConfig.REDIRECT_URI);
    return Promise.resolve({
        token: '115326220526797671902',
        user_details: {
            sub: '115326220526797671902',
            name: 'Byvr ai',
            given_name: 'Byvr',
            family_name: 'ai',
            picture: 'https://lh3.googleusercontent.com/a/ACg8ocIbDX5NAToc72g1Qc1bnRmv4ZLYYvaFF7S4K7-Je3nP=s96-c',
            email: 'byvr.ai@gmail.com',
            email_verified: true,
            locale: 'en',
        },
    });
};

// Fetch OIDC configuration for a given provider
const getOIDCConfig = (provider) => {
    return fetch(`${baseAPIPath}/v1/api/auth/OIDCConfig/${provider}`)
        .then((resp) => resp.json())
        .then((response) => response[provider]);
};

// Store user information in the session
const storeUserInfoInSession = async (user_details) => {
    const response = await fetch(`${baseAPIPath}/v1/api/auth/user_info`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(user_details),
    });
};

// Retrieve user information from the session
const getUserInfoInSession = async () => {
    const response = await fetch(`${baseAPIPath}/v1/api/auth/user_info`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return response.json();
};

// Authenticate with Google in production
const authenticateWithGoogle = () => {
    return getOIDCConfig('google')
        .then((oidcConfigResp) => {
            OIDCConfig.CLIENT_ID = oidcConfigResp.client_id;
            OIDCConfig.REDIRECT_URI = window.location.origin + "/google";
            const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${OIDCConfig.CLIENT_ID}&redirect_uri=${OIDCConfig.REDIRECT_URI}&response_type=code&scope=${OIDCConfig.SCOPES}`;
            window.location.href = authUrl;
        });
};

// Authenticate with Facebook
const authenticateWithFacebook = () => {
    return getOIDCConfig('google')
        .then((oidcConfigResp) => {
            OIDCConfig.REDIRECT_URI = window.location.origin + "/facebook";
            const authUrl = `https://www.facebook.com/v11.0/dialog/oauth?client_id=${OIDCConfig.FACEBOOK_APP_ID}&redirect_uri=${OIDCConfig.REDIRECT_URI}&state={st=state123abc,ds=123456789}&scope=email,public_profile`;
            window.location.href = authUrl;
        })
};
const authenticateWithApple = () => {
    // Apple login implementation
    window.location.href = 'https://appleid.apple.com/auth/authorize?response_type=code&client_id=com.byvr.web&redirect_uri=https://your.redirect.uri&scope=name%20email&state=state&response_mode=fragment';
}
// Fetch user information using the access token
const fetchGoogleUserInfo = async (token) => {

    try {
        const response = await fetch('https://www.googleapis.com/oauth2/v3/userinfo' ,{
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.json();
    } catch (err) {
        console.error('Error fetching user info:', err);
        throw new Error('Failed to fetch user information');
    }
};

// Fetch user information using the access token
const fetchFacebookUserInfo = async (access_token ) => {

    try {
        const response = await fetch('https://graph.facebook.com/v12.0/me?fields=id,name,email&access_token='+access_token );
        return response.json()
    } catch (err) {
        console.error('Error fetching user info:', err);
        throw new Error('Failed to fetch user information');
    }
};

// Exchange authorization code for access token in production
const exchangeCodeForToken = async (code) => {
    const provider = location.pathname.includes('facebook') ? 'facebook' : 'google';
    try {
        const oidcConfigResp = await getOIDCConfig(provider);
        if (oidcConfigResp) {
            OIDCConfig.CLIENT_ID = oidcConfigResp.client_id;
            OIDCConfig.CLIENT_SECRET = oidcConfigResp.client_secret;
            OIDCConfig.REDIRECT_URI = window.location.origin + "/" + provider;
            OIDCConfig.TOKEN_URI = oidcConfigResp.token_uri;
            OIDCConfig.USERINFO_URI = oidcConfigResp.userinfo_uri;
        }
        const response = await fetch(OIDCConfig.TOKEN_URI, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                code,
                client_id: OIDCConfig.CLIENT_ID,
                client_secret: OIDCConfig.CLIENT_SECRET,
                redirect_uri: OIDCConfig.REDIRECT_URI,
                grant_type: 'authorization_code',
            }),
        });
        const data = await response.json();

        if (data.access_token) {
            window.history.pushState({}, null, OIDCConfig.REDIRECT_URI);
            let user_details  =  provider == "google" ? await fetchGoogleUserInfo(data.access_token) : await fetchFacebookUserInfo(data.access_token)
            return {
                "token": data.access_token,
                "user_details": user_details
            }
        }
        throw new Error('Failed to retrieve access token');
    } catch (err) {
        console.error('Error exchanging code for token:', err);
        throw new Error('Failed to exchange authorization code for token');
    }
};

// User logout function
const userLogout = async (user_details) => {
    const response = await fetch(`${baseAPIPath}/v1/api/auth/logout`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(user_details),
    });
};

const createUser = async (user_details,profile) => {
    console.log(JSON.stringify(user_details.user_details));
    const bodyData = {
        ...user_details.user_details,
        profile: profile,
    };
    const response = await fetch(`${baseAPIPath}/v1/api/users/create_user`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyData),
    });
    const result = await response.json();
    if (result.status == 1) {
        Swal.fire({
            title: 'Message',
            text: result.message,
            icon: 'info',
        });
    }

    return result;


};

const checkUser = async (user_details) => {
    console.log(JSON.stringify(user_details));
    const response = await fetch(`${baseAPIPath}/v1/api/users/user_approval_status`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(user_details.user_details),
    });
    const result = await response.json();
    if (result.message != "Approved User") {
        Swal.fire({
            title: 'Message',
            text: result.message,
            icon: 'info',
        });
    }
};


// Export authentication services
const authServices = {
    exchangeCodeForToken: process.env.NODE_ENV === 'development' ? exchangeCodeForTokenDev : exchangeCodeForToken,
    authenticateWithGoogle: process.env.NODE_ENV === 'development' ? authenticateWithGoogleDev : authenticateWithGoogle,
    authenticateWithFacebook,
    storeUserInfoInSession,
    authenticateWithApple,
    getUserInfoInSession,
    userLogout,
    createUser,
    checkUser,
};

export default authServices;
