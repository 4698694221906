import React, { useState, useEffect } from 'react';
import { Grid, TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { housedetails } from '../../features/house/houseSlice';
import SliderInputField from '../SliderInputField';
import { Padding } from '@mui/icons-material';


function RealStateSetting() {
    const dispatch = useDispatch();
    const houseDetail = useSelector((state) => state.house.houseDetail);

    const [formData, setFormData] = useState({
        house_price: 0,
        down_payment_percent: 0,
        property_tax_rate: 0,
        insurance_cost: 0,
        buying_closing_costs: 0,
        condo_fees: 0,
        additional_monthly_costs: 0,
        house_price_appreciation: 0,
        interest_rate: 0,
        loan_term :15 ,
        hold_for_years: 20,
    });

    useEffect(() => {
        if (houseDetail) {
            setFormData((prev) => ({
                ...prev,
                house_price: houseDetail.house_price || '',
                down_payment_percent: houseDetail.down_payment_percent || '',
                property_tax_rate: houseDetail.property_tax_rate || '',
                insurance_cost: houseDetail.insurance_cost || houseDetail.house_price / 200,
                buying_closing_costs: houseDetail.buying_closing_costs || '',
                condo_fees: houseDetail.HOA || '',
                additional_monthly_costs: houseDetail.additional_monthly_costs || '',
                house_price_appreciation: houseDetail.house_price_appreciation || 4,
                interest_rate: houseDetail.interest_rate || '',
                loan_term : houseDetail.loan_term || 15,
                hold_for_years: houseDetail.hold_for_years || 20,
            }));
        }
    }, [houseDetail]);

    const handleFieldChange = (field, value , max=100) => {
        let parsedValue;
        if (field === 'house_price_appreciation' || field === 'down_payment_percent' ||
            field === 'property_tax_rate' ||
            field === 'buying_closing_costs' || field === 'interest_rate') {
            parsedValue = value === '' ? 0 : Math.max(0, Math.min(max, parseFloat(value)));
        } else if(field === 'insurance_cost') {
            parsedValue = value === '' ? 0 : (parseFloat(value)>parseFloat(max)?max:value);
        }else {
            parsedValue = value === '' ? 0 : parseFloat(value);
        }
        setFormData((prev) => ({
            ...prev,
            [field]: parsedValue,
        }));
        const updatedHouseData = { ...houseDetail, [field]: parsedValue };
        dispatch(housedetails(updatedHouseData));
    };
    return (
        <div>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="Home Price"
                        variant="outlined"
                        size="small"
                        value={formData.house_price}
                        onChange={(e) => handleFieldChange('house_price', e.target.value)}
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth variant="outlined" size="small">
                        <InputLabel>Loan Term (Years)</InputLabel>
                        <Select
                            value={formData.loan_term}
                            onChange={(e) => handleFieldChange('loan_term', e.target.value)}
                            label="Duration"
                        >
                            <MenuItem value={10}>10 Years Fixed</MenuItem>
                            <MenuItem value={15}>15 Years Fixed</MenuItem>
                            <MenuItem value={20}>20 Years Fixed</MenuItem>
                            <MenuItem value={30}>30 Years Fixed</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <SliderInputField
                        label="Down Payment(%)"
                        value={formData.down_payment_percent}
                        onChange={(newValue) => handleFieldChange('down_payment_percent', newValue,100)}
                        min={0}
                        max={100}
                        step={0.01}
                        isPercentage={true} 
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <SliderInputField
                        label="Interest Rate(%)"
                        value={formData.interest_rate}
                        onChange={(newValue) => handleFieldChange('interest_rate', newValue,20)}
                        min={0}
                        max={20}
                        step={0.01}
                        isPercentage={true} 
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <SliderInputField
                        label="Property Tax(%)"
                        value={formData.property_tax_rate}
                        onChange={(newValue) => handleFieldChange('property_tax_rate', newValue,10)}
                        min={0}
                        max={10}
                        step={0.01}
                        isPercentage={true} 
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <SliderInputField
                        label="Home Insurance"
                        value={formData.insurance_cost}
                        onChange={(newValue) => handleFieldChange('insurance_cost', newValue,houseDetail.house_price ? houseDetail.house_price / 10 : 1000)}
                        min={0}
                        max={houseDetail.house_price ? houseDetail.house_price / 20 : 10000}
                        step={1} 
                        isPercentage={false} 
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="Closing Cost(%)"
                        variant="outlined"
                        size="small"
                        value={formData.buying_closing_costs}
                        onChange={(e) => handleFieldChange('buying_closing_costs', e.target.value)}
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{ step: '0.01', min: 0, max: 100 }}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="Condo/HOA Fees"
                        variant="outlined"
                        size="small"
                        value={formData.HOA}
                        onChange={(e) => handleFieldChange('condo_fees', e.target.value)}
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="Home Appreciation(%)"
                        variant="outlined"
                        size="small"
                        value={formData.house_price_appreciation}
                        onChange={(e) => handleFieldChange('house_price_appreciation', e.target.value)}
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{ step: '0.01', min: 0, max: 100 }}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="Additional Monthly Costs"
                        variant="outlined"
                        size="small"
                        value={formData.additional_monthly_costs}
                        onChange={(e) => handleFieldChange('additional_monthly_costs', e.target.value)}
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth variant="outlined" size="small">
                        <InputLabel>Hold for (Years)</InputLabel>
                        <Select
                            value={formData.hold_for_years}
                            onChange={(e) => handleFieldChange('hold_for_years', e.target.value)}
                            label="Duration"
                        >
                            <MenuItem value={10}>10 Years</MenuItem>
                            <MenuItem value={15}>15 Years</MenuItem>
                            <MenuItem value={20}>20 Years</MenuItem>
                            <MenuItem value={30}>30 Years</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </div>
    );
}

export default RealStateSetting;
