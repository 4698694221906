import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    houseDetail: {},
    houseload:false
};

const houseSlice = createSlice({
    name: 'house',
    initialState,
    reducers: {
        housedetails: (state, action) => {
            state.houseDetail = action.payload;
        },
        setHouseLoading: (state, action) => {
            state.houseload = action.payload;
        },
    },
});

export const { housedetails,setHouseLoading } = houseSlice.actions;
 
export default houseSlice.reducer;
