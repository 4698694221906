import React, { useState } from 'react';
import {
    Box,
    IconButton,
    Avatar,
    MenuItem,
    Menu,
    List,
    ListItem,
    Drawer,
    Button,
    Modal,
    TextField,
    Typography,
    CircularProgress,
} from '@mui/material';
import {
    AccountCircle,
    Menu as MenuIcon,
} from '@mui/icons-material';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';  // Import react-scroll's Link
import Cookies from 'js-cookie';
import authServices from '../Services/authService';
import Swal from 'sweetalert2';
import './ByvrMenuAppBar.css';

const baseAPIPath = process.env.REACT_APP_ENV && process.env.REACT_APP_ENV == "prod" ? "https://beta.byvr.ai" : "";
const getHeaderStyles = (pathname) => {
    const paths = {
        fixed: ['/', ''],
        dark: ['/bulk-analytics', '/login', '/analytics', '/profile']
    };

    return {
        position: paths.fixed.includes(pathname) ? 'fixed' : 'unset',
        backgroundColor: paths.dark.includes(pathname) ? 'black' : 'transparent'
    };
};

export default function MenuAppBar({ userData, clearUserData }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileOpen, setMobileOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [issue, setIssue] = useState('');
    const [attachment, setAttachment] = useState(null);
    const [attachmentPreview, setAttachmentPreview] = useState(null);
    const [loading, setLoading] = useState(false); // Add loading state
    const [submitLoading, setSubmitLoading] = useState(false); // Add submit loading state
    const navigate = useNavigate();
    const location = useLocation();

    const goHome = () => {
        navigate('/');
    };

    const handleLogin = () => {
        const navPath = '/login';
        navigate(navPath);
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleProfile = () => {
        // setAnchorEl(null);
        const navPath = '/profile';
        navigate(navPath);
    };

    const handleMyAccount = () => {
        setAnchorEl(null);
    };

    const handleSignOut = () => {
        logoutClientSide();
        logoutServerSide(userData);
        setAnchorEl(null);
        // alert('You have been logged out');
        goHome();
    };

    const logoutClientSide = () => {
        Cookies.remove('session');
        clearUserData();
    };

    const logoutServerSide = (userData) => {
        authServices.userLogout(userData);
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => {
        setModalOpen(false);
        setIssue('');
        setAttachment(null);
        setAttachmentPreview(null);
    };

    const handleIssueChange = (event) => setIssue(event.target.value);

    const handleAttachmentChange = async (event) => {
        const baseurl="https://byvr-issue-data.s3.us-west-2.amazonaws.com/"
        const file = event.target.files[0];
        const presignedUrl = baseurl+file.name;
        setAttachment(presignedUrl);
        setAttachmentPreview(URL.createObjectURL(file));
        setLoading(true); // Set loading to true

        try {

            // async function uploadFile(file) {
                const fileName = file.name;
            
                // Request the pre-signed URL from the backend
                const presignedData = await getPresignedUrl(fileName);
            
                // Prepare the form data to upload the file to S3
                const formData = new FormData();
                for (const key in presignedData.fields) {
                    formData.append(key, presignedData.fields[key]);
                }
                formData.append('file', file);
            
                // Use fetch to send the file to S3 via the pre-signed URL
                const upload = await fetch(presignedData.url, {
                    mode: 'no-cors',
                    method: 'POST',
                    body: formData
                });
            // console.log(upload,"upload");
                if (upload.ok) {
                    console.log('File uploaded successfully!');
                } else {
                    console.log('File upload failed.');
                }
            // }
        } catch (error) {
            console.error('Error uploading attachment:', error);
            alert('Error uploading attachment');
            setLoading(false);
        } finally {
            setLoading(false); // Set loading to false after the process
        }
    };

    async function getPresignedUrl(fileName) {
        const response = await fetch(`${baseAPIPath}/v1/api/issues/generate-presigned-url`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ file_name: fileName })
        });
    
    
        return response.json(); // This returns the pre-signed URL and form fields
    }
    

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result.split(',')[1]);
            reader.onerror = (error) => reject(error);
        });
    };

    const handleSubmit = async () => {
        setSubmitLoading(true); // Set submit loading to true
        if (!attachment) {
            alert("please upload a snapshot")
            setSubmitLoading(false);
            return;
        }

        try {
            const data = {
                email: userData.user_details.email,
                issue: issue,
                attachment: attachment,
            };

            const bugReportResponse = await fetch('https://script.google.com/macros/s/AKfycbyWHrZi_ha4n31GIfE31yv9xZTGpBLgBCE7I2-Nzh_Tna8ZXGND2al4ckU8u7VKb1ugxA/exec', {
                method: 'POST',
                mode: 'no-cors',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (bugReportResponse) {
                Swal.fire({
                    title: 'Message',
                    text: 'Bug reported successfully',
                    icon: 'info',
                });
                handleModalClose();
            } else {
                Swal.fire({
                    title: 'Message',
                    text: 'error',
                    icon: 'error',
                });
                handleModalClose();
            }
        } catch (error) {
            console.error('Error reporting bug:', error);
            alert('Error reporting bug');
        } finally {
            setSubmitLoading(false); // Set submit loading to false after the process
        }
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <List>
                <ListItem button>
                <RouterLink to="/" className="nav-link">ABOUT BYVR</RouterLink>
                </ListItem>
                <ListItem button>
                <RouterLink to="/" className="nav-link">PRICING</RouterLink>
                </ListItem>
                {userData ? (
                    <>
                        <ListItem button>
                            <Avatar alt="" src={userData.picture || <AccountCircle />} />
                        </ListItem>
                        <ListItem button onClick={handleProfile}>Profile</ListItem>
                        <ListItem button onClick={handleMyAccount}>My account</ListItem>
                        <ListItem button onClick={handleSignOut}>Sign Out</ListItem>
                    </>
                ) : (
                    <ListItem button onClick={handleLogin}>
                         <RouterLink to="/" className="nav-link link-color lc"><i className="fa fa-plus"></i> Join / Sign in</RouterLink>
                    </ListItem>
                )}
            </List>
            {userData && ( // Show button only if userData is available
                <Button variant="contained" color="secondary" onClick={handleModalOpen}>
                    Report Bug
                </Button>
            )}
        </Box>
    );

    return (
        <Box 
            className={`main-header header-transparent sticky-header ${location.pathname === '/' ? 'home-header' : 'inner-header'}`}
            style={getHeaderStyles(location.pathname)}
        >
            <Box className="container">
                <nav className="navbar navbar-expand-lg navbar-light">
                <RouterLink to="/" className="navbar-brand logo navbar-brand d-flex w-25 mr-auto">
                        <img src="./img/logos/logo.png" alt="logo" />(beta)
                </RouterLink>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ display: { sm: 'none' }, backgroundColor: "#fff" }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box className="collapse navbar-collapse" id="navbarSupportedContent" sx={{ display: { xs: 'none', sm: 'flex' } }}>
                        <List className="navbar-nav justify-content-center">
                            <li className="nav-item w-auto">
                            <ScrollLink
                                    to="mission-section"
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={500}
                                    className="nav-link"
                                >
                                    BYVR's Mission
                            </ScrollLink>
                            </li>
                            <li className="nav-item w-auto">
                            <ScrollLink
                                    to="products-section"
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={500}
                                    className="nav-link"

                                >
                                    Products
                                </ScrollLink>
                            </li>
                            <li className="nav-item w-auto">
                            <ScrollLink
                                    to="contact-section"
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={500}
                                    className="nav-link"
                                >
                                    Contact Us
                                </ScrollLink>
                            </li>
                        </List>
                        {userData ? (
                            <div className="nav navbar-nav ml-auto justify-content-end">
                                <IconButton
                                    size="small"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleMenu}
                                    color="inherit"
                                >
                                    {userData.picture ? <Avatar alt="" src={userData.picture} /> : <AccountCircle style={{ color : "white"}}/>}
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    <MenuItem onClick={handleProfile}>Profile</MenuItem>
                                    <MenuItem onClick={handleMyAccount}>My account</MenuItem>
                                    <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
                                </Menu>
                            </div>
                        ) : (
                            <List className="nav navbar-nav ml-auto justify-content-end">
                                <ListItem className="nav-item" onClick={handleLogin}>
                                <RouterLink to="/" className="link-color lc"><i className="fa fa-plus"></i> Join / Sign in</RouterLink>
                                </ListItem>
                            </List>
                        )}
                    </Box>
                    {userData && ( // Show button only if userData is available
                <Button variant="contained" color="secondary" onClick={handleModalOpen}>
                    Report Bug
                </Button>
            )}
                </nav>
            </Box>
            <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true,
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
                }}
            >
                {drawer}
            </Drawer>
            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="report-bug-modal-title"
                aria-describedby="report-bug-modal-description"
            >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4 }}>
                    <Typography id="report-bug-modal-title" variant="h6" component="h2">
                        Report Bug
                    </Typography>
                    <TextField
                        fullWidth
                        label="Issue"
                        variant="outlined"
                        margin="normal"
                        value={issue}
                        onChange={handleIssueChange}
                    />
                    <Button
                        variant="contained"
                        component="label"
                        fullWidth
                        sx={{ mt: 2 }}
                    >
                        Upload Snapshot
                        <input
                            type="file"
                            hidden
                            onChange={handleAttachmentChange}
                        />
                    </Button>
                    {attachmentPreview && (
                        <Box sx={{ mt: 2, textAlign: 'center' }}>
                            <img src={attachmentPreview} alt="Attachment Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                        </Box>
                    )}
                    {loading ? (
                        <Box sx={{ textAlign: 'center' }}>
                            <CircularProgress />
                            <Typography variant="body2">Uploading file...</Typography> {/* Added text here */}
                        </Box>
                    ) : (
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            sx={{ mt: 2 }}
                            onClick={handleSubmit}
                            disabled={submitLoading} // Disable button while loading
                        >
                            {submitLoading ? (
                                <CircularProgress size={24} color="inherit" /> // Loader for submit button
                            ) : (
                                'Submit'
                            )}
                        </Button>
                    )}
                </Box>
            </Modal>
        </Box>
    );
}
