import React, { useEffect, useState } from 'react';
import { Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { housedetails } from '../../features/house/houseSlice';
import { setUserData, clearUserData } from '../../features/user/userSlice';

function UserStatusSetting() {
    const dispatch = useDispatch();
    const houseDetail = useSelector((state) => state.house.houseDetail); 
    const userData = useSelector((state) => {
        return state.user?.userData
    });

    const [formData, setFormData] = useState({
        buyerStatus: houseDetail.buyerStatus || 1, 
        creditScore:  userData && userData.creditScore ? userData.creditScore  : 3, 
    });

    useEffect(() => {
        if (houseDetail) {
            setFormData((prev) => ({ ...prev, 
                buyerStatus: houseDetail.buyerStatus || 1 ,
                creditScore :  userData && userData.creditScore ? userData.creditScore  : 3 ,
            }));
        }
    }, [houseDetail]);

    const handleFieldChange = (value) => {
        setFormData((prev) => ({ ...prev, buyerStatus: value })); 
        const updatedHouseData = { ...houseDetail, buyerStatus: value }; 
        dispatch(housedetails(updatedHouseData)); 
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                    <FormControl fullWidth variant="outlined" size="small">
                    <InputLabel id="buyer-status-label">Buyer Status</InputLabel>
                        <Select
                        labelId="buyer-status-label"
                        id="buyer-status"
                        value={formData.buyerStatus} 
                        onChange={(e) => handleFieldChange(e.target.value)} 
                        label="Buyer Status"
                    >
                        <MenuItem value={1}>First-Time Buyer</MenuItem>
                        <MenuItem value={2}>Home Owner</MenuItem>
                    </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth variant="outlined" size="small">
                        <InputLabel>Credit Score</InputLabel>
                        <Select
                            value={formData.creditScore}
                            onChange={(e) => handleFieldChange('credit_score', e.target.value)}
                            label="Credit Score"
                        >
                            <MenuItem value={1}>Low (&lt; 680)</MenuItem>
                            <MenuItem value={2}>High ( 680 - 740)</MenuItem>
                            <MenuItem value={3}>Very High (&gt; 740)</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
        </Grid>
    );
}

export default UserStatusSetting;
