import { Box } from '@mui/material';
import React, { useState,useEffect } from 'react';
import Pagination from '@mui/material/Pagination'; 
import { formatValue } from '../utils/Utils';
import { getHouseList, google_house_details } from '../Services/dataService';


function ByvrHouseListView({ addresses, selectedAddress, inputLocation, onRowClick ,paginationDetails }) {
    const [currentPage, setCurrentPage] = useState(paginationDetails.currentPage || 1);
    const [localAddresses, setLocalAddresses] = useState(addresses || []);
    const [localPaginationDetails, setLocalPaginationDetails] = useState(paginationDetails || {});

    useEffect(() => {
        if (paginationDetails) {
            setLocalPaginationDetails(paginationDetails);
            setCurrentPage(paginationDetails.currentPage || 1);
        }
    }, [paginationDetails]);
    
    useEffect(() => {
        if (addresses) {
            setLocalAddresses(addresses);
        }
    }, [addresses]);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        const street_address = new URLSearchParams(window.location.search).get('address');
        const google_place_id = new URLSearchParams(window.location.search).get('google_place_id');
        
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('page_number', value);
    
        window.history.pushState(null, '', `?${searchParams.toString()}`);
    
        const page_number = searchParams.get('page_number');
        getHouseList(street_address, google_place_id, page_number)
            .then((houseList) => {
                setLocalAddresses(houseList.properties); 
                setLocalPaginationDetails({
                    resultsPerPage: houseList.resultsPerPage,
                    totalPages: houseList.totalPages,
                    totalResultCount: houseList.totalResultCount,
                    currentPage: houseList.currentPage
                });
            })
            .catch(() => {
                setLocalAddresses([]); 
            });
    };
    

    const totalResultCount = localPaginationDetails.totalResultCount || 10;
    const totalPages = localPaginationDetails.totalPages || '';
    const totalPropertyString = totalPages
        ? `Review ${totalResultCount} plus properties`
        : '';


    return (
        <div className="msb" id="msb" style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <nav className="navbar navbar-default" role="navigation" style={{ flex: '1', overflowY: 'auto' ,height : '70vh' }}>
                <div className="side-menu-container">
                    <ul className="nav navbar-nav">
                        <li className="panel panel-default">
                            <div className="table-responsive" style={{ paddingBottom: '100px' }}>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col" colSpan="4">
                                            {totalPropertyString}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {localAddresses.map((address, index) => (
                                            <Box
                                                key={index}
                                                className="toggle"
                                                onClick={() => onRowClick(address)}
                                                role="button"
                                                tabIndex={0}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <tr>
                                                    <td>
                                                        <div className="custom-control custom-checkbox">
                                                            <input
                                                                type="checkbox"
                                                                className="custom-control-input"
                                                                id={`customCheck${index}`}
                                                                checked={selectedAddress.address === address.address}
                                                                readOnly
                                                            />
                                                            <label
                                                                className="custom-control-label"
                                                                htmlFor={`customCheck${index}`}
                                                            ></label>
                                                        </div>
                                                    </td>
                                                    <td className="hous">
                                                        <img src={address.imgSrc} alt="logo" />
                                                    </td>
                                                    <td className="w-100">
                                                        <br />
                                                        {address.address}
                                                        <br />
                                                        <Box>
                                                            <div>
                                                                <span style={{ fontWeight: 'bold' }}>Bed : {address.bedrooms}</span>&nbsp;&nbsp;
                                                                <span style={{ fontWeight: 'bold' }}>Bath : {address.bathrooms}</span>&nbsp;&nbsp;
                                                            </div>
                                                            <div>
                                                                <span style={{ fontWeight: 'bold' }}>Est : {formatValue(address.zestimate)}</span>&nbsp;&nbsp;
                                                                <span style={{ fontWeight: 'bold' }}>Rent : {formatValue(address.rentZestimate)}</span>
                                                            </div>
                                                        </Box>
                                                        <br />
                                                    </td>
                                                </tr>
                                            </Box>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div id="dropdown-lvl1" className="panel-collapse collapse">
                                <div className="panel-body">
                                    <img src="img/grf.jpg" alt="logo" />
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>

            {/* Pagination Component */}
            <div
                className="pagination-container"
                style={{
                    position: 'fixed',
                    bottom: '0', 
                    left: '0', 
                    height:'30vh',
                    zIndex: 1000, 
                    backgroundColor: '#f8f9fa',
                    padding: '10px',
                    borderRadius: '8px',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                    width:'100%'
                }}
            >
                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                    variant="outlined"
                    shape="rounded"
                    sx={{
                        "& .MuiPaginationItem-root": {
                            fontSize: "1rem",
                            alignItems: "center",
                            fontWeight: "bold",
                            mx: 1, 
                            border: "1px solid #ccc",
                            borderRadius: "50%",
                            width: "40px",
                            height: "40px",
                            lineHeight: "40px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        },
                        "& .Mui-selected": {
                            backgroundColor: "#1976d2",
                            color: "#fff",
                        },
                    }}
                />
            </div>
        </div>
    );
}

export default ByvrHouseListView;
