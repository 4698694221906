/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
import React, { useState, useEffect } from 'react';
import { Card, CardContent, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CompareProfitLineChart from '../../Charts/CompareProfitLineChart';
import { hexToRGB } from '../../utils/Utils';
import DashboardConfig from './DashboardConfig';
import { getHouseDetails } from '../../Services/dataService';
import { generateChartData } from '../../utils/calculations/hhProfitCalculator';
import logger from '../../utils/logger';
import { useSearchParams } from 'react-router-dom';
import { getAnalyticsInfo } from '../../Services/analyticsService';
import { useSelector, useDispatch } from 'react-redux';
import { housedetails ,setHouseLoading} from '../../features/house/houseSlice';
import ReactMarkdown from 'react-markdown';
import explanationMd from './Value_growth_explanation.md';
import { getFullHouseDetails } from '../../Services/dataService';

const convertDatatoEquityChartData = (current_data, new_data) => {
    let chartNumber = current_data.datasets.length + 1
    let profits = new_data.house_equity.map((item, index) => item - new_data.total_expense[index])
    let chart_data = {
        labels: new_data.years,
        datasets: [...current_data.datasets, {
            stack: 'House Equity',
            data: profits,
            backgroundColor: `rgba(${hexToRGB('#22c55e')}, 0.08)`,
            borderColor: '#22c55e',
            borderWidth: 2,
            tension: 0,
            pointRadius: 0,
            pointHoverRadius: 3,
            pointBackgroundColor: '#22c55e',
            clip: 20,
            label: 'Profit Chart ' + chartNumber,
        }]
    };

    return chart_data;
};

function ByvrCompareValueGrowth({ address, width, height, singleAddress, mobileView, type }) {
    const [chartData, setChartData] = useState({ labels: [], datasets: [] });
    const [explanation, setExplanation] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        // Load markdown content
        fetch(explanationMd)
            .then(response => response.text())
            .then(text => setExplanation(text));
    }, []);

    const setAnalyticsData = (houseDetail) => {
        const analytics_data = houseDetail.analytics_data
            ? houseDetail.analytics_data
            : generateChartData(houseDetail, 20);
            dispatch(setHouseLoading(false));
        setChartData(convertDatatoEquityChartData(chartData, analytics_data));
        logger.debug(chartData);
    }
    const [searchParams] = useSearchParams();
    const houseData = useSelector((state) => state.house.houseDetail);


    const handleSubmitconfig = async () => {
        console.log('Button clicked!');

        if (houseData && Object.keys(houseData).length > 0) {
            const street_address = searchParams.get('address');
            if (street_address) {
                try {
                    const houseDetail = await getAnalyticsInfo(street_address, houseData);
                    setAnalyticsData(houseDetail);
                } catch (error) {
                    console.error('Error fetching analytics info:', error);
                }
            } else {
                console.warn('No address found in searchParams');
            }
        } else {
            console.warn('houseData is either undefined or empty');
        }
    };

    useEffect(() => {
        if(houseData.address !== address.street_address ){
        getHouseDetails(address.street_address, address.google_place_id).then((houseDetail) => {
            var fullHouseData = {};
            setAnalyticsData(houseDetail);
            if (houseDetail && Object.keys(houseDetail).length > 0) {
                fullHouseData = getFullHouseDetails(houseDetail);;
                fullHouseData.address = address.street_address;
                fullHouseData.google_place_id = address.google_place_id;
            }
            dispatch(housedetails(fullHouseData));
        });

    }else{
        setAnalyticsData(houseData);
    }

    }, [address]);

    return (
        <Card>
            <CardContent>
                <CompareProfitLineChart data={chartData} width={width} height={height} singleAddress={singleAddress} mobileView={mobileView} type={type} />
                <div style={{ fontSize: '12px', color: 'gray', textAlign: 'center', marginTop: '10px' }}>Not a financial or tax advice. Please contact your advisor as needed. Meant for informational purposes only.</div>

                <Accordion defaultExpanded className="accordion-margin">
                    <AccordionSummary
                        expandIcon={<ArrowDownwardIcon className="accordion-icon-red" />}
                        aria-controls="Configure To Compare"
                        id="configure"
                    >
                        <Typography variant="h6" component="h6">Configure To Compare</Typography>
                    </AccordionSummary>
                    <AccordionDetails className="accordion-details">
                        <DashboardConfig setAnalyticsData={setAnalyticsData} />
                    </AccordionDetails>

                    <div className="accordion-button-container">
                        <button className="accordion-button" onClick={handleSubmitconfig}>Add</button>
                    </div>
                </Accordion>

                <Accordion className="accordion-margin">
                    <AccordionSummary
                        expandIcon={<ArrowDownwardIcon className="accordion-icon-red" />}
                        aria-controls="explanation"
                        id="explnation"
                    >
                        <Typography variant="h6" component="h6">User Guide for Byvr Investment Compare Tool</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ReactMarkdown>{explanation}</ReactMarkdown>
                    </AccordionDetails>
                </Accordion>
            </CardContent>

        </Card>
    );
}

export default ByvrCompareValueGrowth;
