import log from 'loglevel';
import store from '../store.js';

import { setUserDetails } from '../features/user/userSlice';

export const getUserByEmail = async (email) => {
    const response = await fetch(`/v1/api/users/email/${email}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    });
    const result = await response.json();
    return result

};

export const fetchProfileData = async (email) => {
    try {
        const response = await fetch(`/v1/api/users/email/${email}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const result = await response.json();
        const data = result.profile;
        return data;

    } catch (error) {
        console.error('Error fetching profile data:', error);
    }
};

export const updateProfileData = async (email, profile) => {
    const response = await fetch(`/v1/api/users/update_profile`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, profile })
    });
    return response;
};

export default {
    getUserByEmail,
    fetchProfileData,
    updateProfileData
}