/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import log from 'loglevel';
import React, { useState, useEffect } from 'react';
import { Grid, TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import './ByvrPagesLayout.css';
import store from '../store.js';
import Swal from 'sweetalert2';
import userService from '../Services/userService.js';
import authServices from '../Services/authService.js';
import { json } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


export default function ByvrProfile() {

    const [buyerStatus, setBuyerStatus] = useState('');
    const [maritalStatus, setMaritalStatus] = useState('');
    const [children, setChildren] = useState('');
    const [lifestyle, setLifestyle] = useState('');
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneError, setPhoneError] = useState(false);

    const formatPhoneNumber = (value) => {
        // Remove all non-numeric characters
        const numbersOnly = value.replace(/[^\d]/g, '');

        // Check if the input exceeds 11 digits (US format includes country code)
        if (numbersOnly.length > 11) {
            setPhoneError(true);
            return phoneNumber; // Return the current valid number
        }

        setPhoneError(false);

        // Format the phone number
        let formattedNumber = '';
        if (numbersOnly.length > 0) {
            formattedNumber += `+${numbersOnly[0]}`;
        }
        if (numbersOnly.length > 1) {
            formattedNumber += ` (${numbersOnly.slice(1, 4)}`;
        }
        if (numbersOnly.length > 4) {
            formattedNumber += `)-${numbersOnly.slice(4, 7)}`;
        }
        if (numbersOnly.length > 7) {
            formattedNumber += `-${numbersOnly.slice(7, 11)}`;
        }

        return formattedNumber;
    };

    const handlePhoneNumberChange = (e) => {
        const inputValue = e.target.value;

        // Format the input value
        const formattedValue = formatPhoneNumber(inputValue);

        // Update the state with the formatted value
        setPhoneNumber(formattedValue);
    };


    const [pinCode, setPinCode] = useState('');
    const [pinCodeError, setPinCodeError] = useState(false);

    const handlePinCodeChange = (e) => {
        const inputValue = e.target.value;

        // Allow only numeric input
        if (!/^\d*$/.test(inputValue)) {
            setPinCodeError(true);
            return;
        }

        // Update the pin code state and clear error
        setPinCode(inputValue);
        setPinCodeError(false);
    };
    const [age, setAge] = useState('');

    const [ageError, setAgeError] = useState(false);

    const handleAgeChange = (e) => {
        const inputValue = e.target.value;

        // Allow only numeric input
        if (!/^\d*$/.test(inputValue)) {
            return;
        }

        // Allow input to be set (even intermediate values like 5 or 0)
        setAge(inputValue);

        // Validate only if the value is not empty
        if (inputValue) {
            const numericAge = Number(inputValue);
            if (numericAge < 10 || numericAge > 110) {
                setAgeError(true);
            } else {
                setAgeError(false);
            }
        } else {
            setAgeError(false);
        }
    };

    const [address, setAddress] = useState('');
    const [incomeLevel, setIncomeLevel] = useState('');
    const [houseType, setHouseType] = useState('');
    const [schoolDistrictType, setSchoolDistrictType] = useState('');
    const [lowCrimeRate, setLowCrimeRate] = useState('');
    const [buildingAge, setBuildingAge] = useState('');
    const [mortgageTerm, setMortgageTerm] = useState('');
    const [keepHouseTerm, setKeepHouseTerm] = useState('');
    const [update_profile, setUpdateProfile] = useState(0)

    // Access email from Redux store using the same structure as in aiService.js
    const state = store.getState();
    const userData = state.user;

    const navigate = useNavigate();

    const email = userData.userData?.user_details?.email ?? null;

    useEffect(() => {
        userService.fetchProfileData().then((data) => {
            setName(data?.name ?? '');
            setPhoneNumber(data?.phoneNumber ?? '');
            setPinCode(data?.pinCode ?? '');
            setAge(data?.age ?? '');
            setAddress(data?.address ?? '');
            setBuyerStatus(data?.buyerStatus ?? '');
            setMaritalStatus(data?.maritalStatus ?? '');
            setChildren(data?.children ?? '');
            setLifestyle(data?.lifestyle ?? '');
            setIncomeLevel(data?.incomeLevel ?? '');
            setHouseType(data?.houseType ?? '');
            setSchoolDistrictType(data?.schoolDistrictType ?? '');
            setLowCrimeRate(data?.lowCrimeRate ?? '');
            setBuildingAge(data?.buildingAge ?? '');
            setMortgageTerm(data?.mortgageTerm ?? '');
            setKeepHouseTerm(data?.keepHouseTerm ?? '');
        });

        if (email) {
            setTimeout(() => {
                userService.fetchProfileData(email).then((data) => {
                    setName(data?.name ?? '');
                    setPhoneNumber(data?.phoneNumber ?? '');
                    setPinCode(data?.pinCode ?? '');
                    setAge(data?.age ?? '');
                    setAddress(data?.address ?? '');
                    setBuyerStatus(data?.buyerStatus ?? '');
                    setMaritalStatus(data?.maritalStatus ?? '');
                    setChildren(data?.children ?? '');
                    setLifestyle(data?.lifestyle ?? '');
                    setIncomeLevel(data?.incomeLevel ?? '');
                    setHouseType(data?.houseType ?? '');
                    setSchoolDistrictType(data?.schoolDistrictType ?? '');
                    setLowCrimeRate(data?.lowCrimeRate ?? '');
                    setBuildingAge(data?.buildingAge ?? '');
                    setMortgageTerm(data?.mortgageTerm ?? '');
                    setKeepHouseTerm(data?.keepHouseTerm ?? '');
                    if (data?.status == 0) {
                        setUpdateProfile(1);
                    }
                });
            }, 2000);
        }

    }, [email]);


    const handleBuyerStatusChange = (event) => {
        setBuyerStatus(event.target.value);
    };

    const handleMaritalStatusChange = (event) => {
        setMaritalStatus(event.target.value);
    };

    const handleChildrenChange = (event) => {
        setChildren(event.target.value);
    };

    const handleLifestyleChange = (event) => {
        setLifestyle(event.target.value);
    };

    const handleHouseTypeChange = (event) => {
        setHouseType(event.target.value);
    };

    const handleSchoolDistrictTypeChange = (event) => {
        setSchoolDistrictType(event.target.value);
    };

    const handleLowCrimeRateChange = (event) => {
        setLowCrimeRate(event.target.value);
    };

    const handleBuildingAgeChange = (event) => {
        setBuildingAge(event.target.value);
    };

    const handleMortgageTermChange = (event) => {
        setMortgageTerm(event.target.value);
    };

    const handleKeepHouseTermChange = (event) => {
        setKeepHouseTerm(event.target.value);
    };

    const handleSubmit = async () => {
        const data = {
            name,
            email,
            phoneNumber,
            pinCode,
            age,
            address,
            buyerStatus,
            maritalStatus,
            children,
            lifestyle,
            incomeLevel,
            houseType,
            schoolDistrictType,
            lowCrimeRate,
            buildingAge,
            mortgageTerm,
            keepHouseTerm
        };

        try {
            // if(update_profile==0){
            // console.log(userData);
            // const createUserResponse = await authServices.createUser(userData.userData,data);
            // if (!createUserResponse.ok) {
            //     throw new Error('Network response was not ok');
            // }
            // }

            const response = await userService.updateProfileData(email, data);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            console.log('Success:', result);

            // Show SweetAlert on successful response
            Swal.fire({
                icon: 'success',
                title: 'Profile Updated',
                text: 'Your profile has been successfully updated!',
            });

            navigate('/')

        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className='main-title text-center MuiBox-root pt4'>
            <div className='container'>
                <div className='shadow'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <h1 className='snheading'>User Details</h1>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-6 mb-4'>
                            <TextField fullWidth label="Name" id="name" value={name} onChange={(e) => setName(e.target.value)} />
                        </div>
                        <div className='col-md-6 mb-4'>
                            <TextField fullWidth label="Email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div className='col-md-6 mb-4'>
                            <TextField
                                fullWidth
                                label="Phone Number"
                                id="phone-number"
                                type="tel"
                                placeholder="Enter your phone number +X (XXX)-XXX-XXXX"
                                value={phoneNumber}
                                onChange={handlePhoneNumberChange}
                                helperText={phoneError ? 'Invalid phone number. Maximum 11 digits allowed (including country code).' : ''}
                                error={phoneError}
                            />
                        </div>
                        <div className='col-md-6 mb-4'>
                        <TextField
                            fullWidth
                            label="Pin Code"
                            id="pin-code"
                            type="tel"
                            placeholder="Enter your pin code"
                            value={pinCode}
                            onChange={handlePinCodeChange}
                            helperText={pinCodeError ? 'Pin Code must contain only numbers.' : ''}
                            error={pinCodeError}
                        />
                        </div>
                        <div className='col-md-6 mb-4'>
                        <TextField
                            fullWidth
                            label="Age"
                            id="age"
                            type="tel"
                            placeholder="Enter your age"
                            value={age}
                            onChange={handleAgeChange}
                            helperText={ageError ? 'Age must be between 10 and 110' : ''}
                            error={ageError}
                        />
                        </div>
                        <div className='col-md-6 mb-4'>
                            <TextField
                                id="address"
                                label="Address"
                                multiline
                                rows={4}
                                placeholder="Enter your address"
                                fullWidth
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='shadow'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <h1 className='snheading'>User Preferences</h1>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-6 mb-4'>
                            <FormControl fullWidth>
                                <InputLabel id="buyer-status-label">First Time Buyer</InputLabel>
                                <Select
                                    labelId="buyer-status-label"
                                    id="buyer-status"
                                    value={buyerStatus}
                                    label="First Time Buyer"
                                    onChange={handleBuyerStatusChange}
                                >
                                    <MenuItem value="yes">Yes</MenuItem>
                                    <MenuItem value="no">No</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        {/* <div className='col-md-6 mb-4'>
                            <FormControl fullWidth>
                                <InputLabel id="marital-status-label">Marital Status</InputLabel>
                                <Select
                                    labelId="marital-status-label"
                                    id="marital-status"
                                    value={maritalStatus}
                                    label="Marital Status"
                                    onChange={handleMaritalStatusChange}
                                >
                                    <MenuItem value="married">Married</MenuItem>
                                    <MenuItem value="unmarried">Unmarried</MenuItem>
                                </Select>
                            </FormControl>
                        </div> */}
                        {/* <div className='col-md-6 mb-4'>
                            <FormControl fullWidth>
                                <InputLabel id="children-label">Number of Children</InputLabel>
                                <Select
                                    labelId="children-label"
                                    id="children"
                                    value={children}
                                    label="Number of Children"
                                    onChange={handleChildrenChange}
                                >
                                    <MenuItem value={0}>None</MenuItem>
                                    <MenuItem value={1}>One</MenuItem>
                                    <MenuItem value={2}>Two</MenuItem>
                                </Select>
                            </FormControl>
                        </div> */}
                        {/* <div className='col-md-6 mb-4'>
                            <TextField
                                fullWidth
                                label="Income Level"
                                id="income-level"
                                placeholder="Enter your income level"
                                value={incomeLevel}
                                onChange={(e) => setIncomeLevel(e.target.value)}
                            />
                        </div> */}
                        <div className='col-md-6 mb-4'>
                            <FormControl fullWidth>
                                <InputLabel id="lifestyle-label">Lifestyle Preferences</InputLabel>
                                <Select
                                    labelId="lifestyle-label"
                                    id="lifestyle"
                                    value={lifestyle}
                                    label="Lifestyle Preferences"
                                    onChange={handleLifestyleChange}
                                >
                                    <MenuItem value="urban">Urban</MenuItem>
                                    <MenuItem value="suburban">Suburban</MenuItem>
                                    <MenuItem value="rural">Rural</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className='col-md-6 mb-4'>
                            <FormControl fullWidth>
                                <InputLabel id="house-type-label">House Type</InputLabel>
                                <Select
                                    labelId="house-type-label"
                                    id="house-type"
                                    value={houseType}
                                    label="House Type"
                                    onChange={handleHouseTypeChange}
                                >
                                    <MenuItem value="condo">Condo</MenuItem>
                                    <MenuItem value="townhouse">Townhouse</MenuItem>
                                    <MenuItem value="duplex">Duplex</MenuItem>
                                    <MenuItem value="single-family">Single Family</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className='col-md-6 mb-4'>
                            <FormControl fullWidth>
                                <InputLabel id="school-district-type-label">School District Type</InputLabel>
                                <Select
                                    labelId="school-district-type-label"
                                    id="school-district-type"
                                    value={schoolDistrictType}
                                    label="School District Type"
                                    onChange={handleSchoolDistrictTypeChange}
                                >
                                    <MenuItem value="no-preference">No Preference</MenuItem>
                                    <MenuItem value="good-highschool">Good Highschool</MenuItem>
                                    <MenuItem value="good-middle-school">Good Middle School and above</MenuItem>
                                    <MenuItem value="good-kindergarten">Good Kindergarten and above</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className='col-md-6 mb-4'>
                            <FormControl fullWidth>
                                <InputLabel id="low-crime-rate-label">Low Crime Rate</InputLabel>
                                <Select
                                    labelId="low-crime-rate-label"
                                    id="low-crime-rate"
                                    value={lowCrimeRate}
                                    label="Low Crime Rate"
                                    onChange={handleLowCrimeRateChange}
                                >
                                    <MenuItem value="no-preference">No Preference</MenuItem>
                                    <MenuItem value="essential">Essential</MenuItem>
                                    <MenuItem value="somewhat-important">Somewhat important</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className='col-md-6 mb-4'>
                            <FormControl fullWidth>
                                <InputLabel id="building-age-label">Building Age</InputLabel>
                                <Select
                                    labelId="building-age-label"
                                    id="building-age"
                                    value={buildingAge}
                                    label="Building Age"
                                    onChange={handleBuildingAgeChange}
                                >
                                    <MenuItem value="new-construction-only">New Construction Only</MenuItem>
                                    <MenuItem value="up-to-10-years-old">Up to 10 Years Old</MenuItem>
                                    <MenuItem value="up-to-20-years-old">Up to 20 Years Old</MenuItem>
                                    <MenuItem value="up-to-30-years-old">Up to 30 Years Old</MenuItem>
                                    <MenuItem value="30-years-or-older">30 Years or Older</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className='col-md-6 mb-4'>
                            <FormControl fullWidth>
                                <InputLabel id="mortgage-term-label">Mortgage Term</InputLabel>
                                <Select
                                    labelId="mortgage-term-label"
                                    id="mortgage-term"
                                    value={mortgageTerm}
                                    label="Mortgage Term"
                                    onChange={handleMortgageTermChange}
                                >
                                    <MenuItem value="15">15</MenuItem>
                                    <MenuItem value="30">30</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className='col-md-6 mb-4'>
                            <FormControl fullWidth>
                                <InputLabel id="keep-house-term-label">How Long Do You Plan to Keep the house</InputLabel>
                                <Select
                                    labelId="keep-house-term-label"
                                    id="keep-house-term"
                                    value={keepHouseTerm}
                                    label="How Long Do You Plan to Keep the house"
                                    onChange={handleKeepHouseTermChange}
                                >
                                    <MenuItem value="5-years">5 Years</MenuItem>
                                    <MenuItem value="10-years">10 Years</MenuItem>
                                    <MenuItem value="15-years">15 Years</MenuItem>
                                    <MenuItem value="20-years">20 Years</MenuItem>
                                    <MenuItem value="25-years">25 Years</MenuItem>
                                    <MenuItem value="30-years">30 Years</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className='row'>
                        {/* <Box className="sbtn button"><Link to="." className="btn btn-outline pricing-btn button-theme">Get
                    Started</Link></Box> */}
                        <div className='col-md-12'>
                            <button className="btn btn-outline pricing-btn button-theme" onClick={handleSubmit}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

