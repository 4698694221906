import ValueGrowth from './ValueGrowth';
import ByvrCompareValueGrowth from './ByvrCompareValueGrowth';
import ByvrCompareProfitGrowth from './ByvrCompareProfitGrowth';
import ExpenseOverTime from './ByvrExpenseOverTime';
import * as React from 'react';
import { Tabs, Tab, Box} from '@mui/material';

function ByvrDashboardView({ selectedAddress }) {
    const [value, setValue] = React.useState("value-growth");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="disabled tabs example">
                    <Tab label="Value Growth" value="value-growth" />
                    <Tab label="Monthly Expense Over Time" value="expense-over-time" />
                    {/* <Tab label="Value Growth (Logarithmic)" value="log-value-growth" />*/}
                    <Tab label="Comparative Growth Analysis" value="comparegrowth" />
                    <Tab label="Comparative Profit Analysis" value="compareprofit" />
                </Tabs>
            </Box>
            {value === 'value-growth' && (
                <ValueGrowth address={selectedAddress} type="linear" width={389} height={400} />
            )}
            {value === 'expense-over-time' && (
                <ExpenseOverTime address={selectedAddress} type="linear" width={389} height={400} />
            )}
            {value === 'comparegrowth' && (
                <ByvrCompareValueGrowth address={selectedAddress} type="linear" width={389} height={400} />
            )}
             {value === 'compareprofit' && (
                <ByvrCompareProfitGrowth address={selectedAddress} type="linear" width={389} height={400} />
            )}
        </div>
    );
}
export default ByvrDashboardView;